<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button
        class="right"
        type="primary"
        size="medium"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content">
      <!-- <TitleModule :title="'预约商品详情'"></TitleModule> -->
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          label-position="left"
          label-width="150px"
          :model="detail"
          style="width: 100%"
        >
          <span class="messTitle">基础信息</span>
          <div class="info-wrapper">
            <el-form-item
              label="商户名称:"
              style="padding-left: 100px; margin-bottom: 0"
            >
              <span>{{ detail.operationName }}</span>
            </el-form-item>
            <el-form-item
              style="padding-left: 100px; margin-bottom: 0"
              label="停车场名称:"
            >
              <span>{{ detail.parkName }}</span>
            </el-form-item>
          </div>
          <span class="messTitle">销售信息</span>
          <div
            class="info-item"
            v-for="(item, index) in detail.staggeredList"
            :key="index"
          >
            <el-form-item
              label="错峰卡名称:"
              style="padding-left: 100px; margin-bottom: 0"
            >
              <span>{{ item.staggeredName }}</span>
            </el-form-item>
            <el-form-item
              label="错峰日期:"
              style="padding-left: 100px; margin-bottom: 0"
            >
              <span>{{ item.startDate }}</span
              ><span> 至 </span><span>{{ item.endDate }}</span>
            </el-form-item>
            <el-form-item
              label="错峰时间:"
              style="padding-left: 100px; margin-bottom: 0"
            >
              <span>{{ item.startTime }}</span
              ><span> - </span><span>{{ item.endTime }}</span>
            </el-form-item>
            <el-form-item
              label="星期配置"
              style="padding-left: 100px; margin-bottom: 0"
            >
              <span
                ><span
                  v-for="(week, index) in item.weeks.split(',')"
                  :key="week"
                  >周{{ ["一", "二", "三", "四", "五", "六", "日"][week - 1]
                  }}<span v-if="index < item.weeks.split(',').length - 1"
                    >、</span
                  >
                </span></span
              >
            </el-form-item>
            <el-form-item
              label="错峰卡数量"
              style="padding-left: 100px; margin-bottom: 0"
            >
              <span>{{ item.berthNumber }}</span>
            </el-form-item>
            <el-form-item
              label="销售金额:"
              prop="account"
              style="padding-left: 100px; margin-bottom: 0"
            >
              <span v-if="item.actualMoney"
                >{{ item.actualMoney / 100 }} 元/天</span
              >
            </el-form-item>
            <el-form-item
              label="销售说明:"
              prop="account"
              style="padding-left: 100px; margin-bottom: 0"
            >
              <span>{{ item.remark }}</span>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {} from "@/common/js/public.js";
// import TitleModule from '@/components/titleModule/titleModule'
export default {
  name: "staggerdManageDetail",
  data() {
    return {
      staggeredParkId: "",
      detail: {
        parkName: "",
        operationName: "",
        goodState: "",
        appointmentStartDate: "",
        appointmentEndDate: "",
        appointmentStartTime: "",
        appointmentEndTime: "",
        berthRetentionTime: "",
        appointmentBerthNumber: "",
        depositMoneyStr: "",
        advanceHour: "",
        cancellTime: "",
        refundMoneyStr: "",
        description: "",
        staggeredList: [],
      },
    };
  },
  methods: {
    queryDetail() {
      this.$axios
        .get("/acb/2.0/staggeredPark/detail", {
          data: {
            staggeredParkId: this.staggeredParkId,
          },
        })
        .then((res) => {
          if (res.value.goodState == 0) {
            res.value.goodState = "未上架";
          } else if (res.value.goodState == 1) {
            res.value.goodState = "出售中";
          } else if (res.value.goodState == 2) {
            res.value.goodState = "已下架";
          }
          if (res.value.appointmentBerthNumber == -1) {
            res.value.appointmentBerthNumber = "不限";
          } else {
            res.value.appointmentBerthNumber =
              res.value.appointmentBerthNumber + "个";
          }
          if (res.value.depositMoneyStr) {
            res.value.depositMoneyStr = (
              res.value.depositMoneyStr / 100
            ).toFixed(2);
          }
          if (res.value.refundMoneyStr) {
            res.value.refundMoneyStr = (res.value.refundMoneyStr / 100).toFixed(
              2
            );
          }
          this.detail = res.value;
        });
    },
  },
  // components: {
  //   TitleModule
  // },
  created() {},
  mounted() {
    // console.log(this.$route.query.staggeredParkId)
    if (this.$route.query.staggeredParkId) {
      this.staggeredParkId = this.$route.query.staggeredParkId;
      this.queryDetail();
    }
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
}

.messTitle {
  display: block;
  height: 40px;
  background: rgba(178, 178, 178, 0.6);
  text-align: left;
  width: 100%;
  line-height: 40px;
  text-indent: 40px;
}

.info-wrapper {
  margin: 20px 0;
}

.info-item {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;

  &:last-child {
    border: none;
  }
}
</style>
